import React from 'react';
import Layout from '../components/Layout';
import { useFormik } from 'formik';

function IndexPage() {
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: '',
        },
        onSubmit: (data) => {
            let formData = new FormData();
            for (let p in data) {
                formData.append(p, data[p]);
            }
            fetch(`/api/contactus.php`, {
                method: `POST`,
                body: formData
            }).then(
                () => {
                    formik.resetForm();
                })
        },
    });

    return (
        <Layout>
            <article id="main">
                <header>
                    <h2>...CONTACT US...</h2>
                </header>

                <section className="wrapper style5">
                    <div className="inner">
                        <section className="contact-page-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="contact-info">
                                            <div className="contact-info-item">
                                                <div className="contact-info-icon">
                                                    <i className="fas fa-map-marked"></i>
                                                </div>
                                                <div className="contact-info-text">
                                                    <h2>Address</h2>
                                                    <span>Lalganga Business Park, 506, Fifth Floor, Pachpedi Naka, Raipur, Chhattisgarh - 492013 </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="contact-info">
                                            <div className="contact-info-item">
                                                <div className="contact-info-icon">
                                                    <i className="fas fa-envelope"></i>
                                                </div>
                                                <div className="contact-info-text">
                                                    <h2>E-mail</h2>
                                                    <br />
                                                    <span>info@jrglobalservices.com</span>
                                                    <span>Phone No: 0771-3554780</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="contact-info">
                                            <div className="contact-info-item">
                                                <div className="contact-info-icon">
                                                    <i className="fas fa-clock"></i>
                                                </div>
                                                <div className="contact-info-text">
                                                    <h2>Office Time</h2>
                                                    <br />
                                                    <span>Mon - Sat | 9:30 am - 6.30 pm</span>
                                                    <span>Sun - Holiday</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-5">
                                    <div className="col-md-6 contact-map">
                                        <iframe title="google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.3463983460138!2d81.65065611424869!3d21.218108186664654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28dcfdd5792991%3A0xcec290ab2a3e81ec!2sLalganga%20Business%20Park!5e0!3m2!1sen!2sin!4v1636627197851!5m2!1sen!2sin" width="100" height="auto" loading="lazy"></iframe>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="contact-page-form">
                                            <h2>Get in Touch Here</h2>
                                            <form onSubmit={formik.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-12 message-input">
                                                        <div className="single-input-field">
                                                            <input type="text" placeholder="Your Name"
                                                                id="name"
                                                                name="name"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.name} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 message-input">
                                                        <div className="single-input-field">
                                                            <input type="email" placeholder="Enter Your Valid E-mail"
                                                                id="email"
                                                                name="email"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.email} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 message-input">
                                                        <div className="single-input-field">
                                                            <textarea placeholder="Write Your Message"
                                                                id="message"
                                                                name="message"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.message} ></textarea>
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <button className="primary" type='submit'>Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </article>

        </Layout>
    );
}

export default IndexPage;